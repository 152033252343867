<template>
  <span>{{
      hour
          ? hourString + ':' + minuteString + ':' + secondString
          : minuteString + ':' + secondString
    }}</span>
</template>

<script>
export default {
  data() {
    return {
      hour: '',
      minute: '',
      second: '',
      timer: '',
    };
  },
  props: {
    remainTime: {
      // 倒计时间总秒数
      default: '',
    },
  },
  watch: {
    remainTime: {
      handler(v) {
        if(v === 0){
          this.$emit('countDowmEnd', true);
        }
        if (v) {
          this.start();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {

      if (this.remainTime > 0) {
        this.hour = Math.floor((this.remainTime / 3600));
        this.minute = Math.floor((this.remainTime / 60) % 60);
        this.second = Math.floor(this.remainTime % 60);
        this.countDowm();
      } else {
        this.minute = 0;
        this.second = 0;
      }
    },
    countDowm() {
      const self = this;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59;
            self.minute -= 1;
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0;
            self.$emit('countDowmEnd', true);
            clearInterval(self.timer);
          } else {
            self.second -= 1;
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59;
            self.minute -= 1;
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1;
            self.minute = 59;
            self.second = 59;
          } else {
            self.second -= 1;
          }
        }

        if(self.hour === 0 && (self.minute === 10 || self.minute === 5) && self.second === 0){
          const index = layer.alert(this.$t('header.Free time near end'),{
            icon:0
          },()=>{
            // window.ECHAT.customMiniChat();
            window.open('https://t.me/YCHpromanager', '_blank');

            layer.close(index)
          })
        }

      }, 1000);
    },
    formatNum(num) {
      return num < 10 ? `0${num}` : `${num}`;
    },
  },
  computed: {
    hourString() {
      return this.formatNum(this.hour);
    },
    minuteString() {
      return this.formatNum(this.minute);
    },
    secondString() {
      return this.formatNum(this.second);
    },
  },
};
</script>
