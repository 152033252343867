<template>
  <div class="playerContent">
    <div class="top">
      <HeaderPc @headerClick="headerClick"/>
      <Notice/>
    </div>
    <div class="content" id="contentBox">
      <div class="left">

        <div class="videoContaienr">
          <VideoPc ref="videoPcRef"/>
        </div>
        <!--
          <RtsVideo
              :living="roomInfo.living"
              class="rtsVideo"
              :rts-fallback-source="roomInfo.hlsPlayerUrl"
              :url="roomInfo.rtcPlayerUrl"
              :cover="roomInfo.cover"
       />-->
        <div class="left_footerContainer">
          <banner/>
          <LiveingRoomBanner/>
          <!--<el-popover popper-class="WechatQrCode" :disabled="lastSignalData.canGetSignal" placement="right"-->
          <!--:visible-arrow="false">-->
          <!--<span>{{ $t('fixRight.Customer') }}</span>-->
          <!--<img :src="roomInfo.qrCode" class="qrCode"> slot="reference"-->
          <span @click="signalHandle">
                 <signal/>
            </span>

          <!--          </el-popover>-->
        </div>
      </div>
      <ChatContainer/>
    </div>
    <div class="footer">
      {{ $t('playerFooter.tip') }}
      <a>{{ $t('playerFooter.Risk warning') }}</a>
      <span style="margin-left: 20px">IP:{{ userIp }}</span>
    </div>
        <FixRight @openLicky="openLicky" width="84px" height="76px"/>
    <Model ref="modelRef"/>
  </div>
</template>

<script>
import layout from './layout.js';
import HeaderPc from '@/components/header/header-pc';
import Model from '@/components/model/pc/index.vue';
import {
  // IS_TOURIST,
  SET_IS_SUPPORT_WEBRTC, SET_TOKEN,
  UPDATE_LINE_TYPE,
} from '@/constants/mutation-types';
import {
  BROADCASTING_ROOM, LOGIN, LUCKY_ACTIVITY,
  PICK_UP_SIGNAL,
  PRIVATE_CHAT,
  REGISTER,
  ROOM_VISIT_PASSWORD,
  VIP_REGISTER_TIP
} from '@/constants/sys.js';
import {
  LINE_TYPE,
} from '@/constants/room';

import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import TRTC from 'trtc-js-sdk';
import Notice from '@/components/header/notice';
import VideoPc from '@/components/video-player/video-pc';
import Banner from '@/components/banner';
import Signal from '@/components/signal';
// import FixRight from '@/components/fixRight';
import {localStorage} from 'utils/storage';
import ChatContainer from '@/components/chat/chatContainer';
import * as mutationTypes from 'constants/mutation-types';
import qs from 'qs';
import socket from 'utils/socket';
import {checkToken, getLastSignal, getLiveRoomInfo} from '@/api';
import {MESSAGETYPE} from '@/components/chat/constants/chat';
import LiveingRoomBanner from '@/components/liveingRoomBanner';
import FixRight from "@/components/fixRight/index.vue";
// import RtsVideo from '@/components/rtsVideo';
// import { checkToken } from '@/api';

export default {
  name: 'App',
  mixins: [layout],
  data() {
    return {
      PICK_UP_SIGNAL,
      windowWidth: 1920,
      passwordLayerId: null
    };
  },
  computed: {
    ...mapState(['freeTime']),
    ...mapState({
      roomId: 'roomId',
      token: 'token',
      tourist: 'tourist',
      userIp: 'userIp',
    }),
    ...mapGetters(['currentRoomId', 'userInfo', 'roomInfo', 'lastSignalData']),
  },
  components: {
    FixRight,
    // RtsVideo,
    LiveingRoomBanner,
    ChatContainer,
    // FixRight,
    Banner,
    VideoPc,
    Notice,
    HeaderPc,
    Signal,
    Model,
  },
  watch: {
    token: {
      handler(v) {
        if (v && !this.tourist && !this.userInfo?.userId) {
          this.getUserInfo();
        }
      },
      immediate: true,
      deep: true,
    },
    currentRoomId: {
      handler(v) {
        if (v) {
          this.handlePlayerInfo();
          const roomPassword = sessionStorage.getItem("roomPassword")
          this.getRoomInfo(roomPassword);
        }
      },
      immediate: true,
      deep: true,
    },
    roomInfo: {
      handler(v) {
        layer.close(this.passwordLayerId)
        // layer.closeAll()
        this.passwordLayerId = null
        //在限制时间段内，被限制播放
        if (v && v.limitPlayer && v.living) {
          // window.ECHAT.customMiniChat();
          this.passwordLayerId = layer.prompt({
            title: this.$t('roomPassword.input title'), formType: 1, shade: 0,
            closeBtn: 0,btn: ['OK'],
          }, (pass, index) => {
            getLiveRoomInfo({id: v.id, password: pass || ''}).then(res => {
              if (res.data.limitPlayer) {
                sessionStorage.removeItem("roomPassword")
                layer.msg(this.$t('roomPassword.password error'));
                setTimeout(()=>{
                  // window.ECHAT.customMiniChat();
                  window.open('https://t.me/YCHpromanager', '_blank');

                },2000)
              } else {
                console.log('this.freeTime:',this.freeTime)
                sessionStorage.setItem("roomPassword",pass)
                layer.close(index);
                if(this.freeTime !== 0){
                  this[mutationTypes.SET_ROOM_INFO](res.data);
                }else{
                  const index = layer.alert(this.$t('header.Free time end'),{
                    icon:0,title:'Tips'
                  },()=>{
                    // window.ECHAT.customMiniChat();
                    window.open('https://t.me/YCHpromanager', '_blank');
                    layer.close(index)
                  })
                }
              }
            }).catch(err => {
              console.log('err:', err)
            })
          });
        }else{
          // console.log('v:',v)
          // console.log('this.freeTime:',this.freeTime)
        }
      },
      immediate: true,
      deep: true,
    }
  },
  mounted() {
    this.windowWidth = document.body.clientWidth;
    const {roomId: queryRoomId, source} = qs.parse(window.location.search.replaceAll('?', ''));
    this[mutationTypes.SET_USER_SOURCE](source);
    this.$nextTick(() => {
      const roomId = queryRoomId || localStorage.get('roomId');
      const {tourist, token} = localStorage.get('authData') || {};
      if (!tourist) {
        checkToken()
            .then((res) => {
              if (!res.data) {
                this.userLogin({autoLoad: true});
                this[SET_TOKEN]({token: '', isTourist: true});
              } else {
                this[SET_TOKEN]({token, isTourist: tourist});
              }
            });
      } else {
        this[SET_TOKEN]({token, isTourist: true});
      }

      this[mutationTypes.SET_ROOM_ID](roomId);
      if (!roomId) {
        this.$refs.modelRef.show(BROADCASTING_ROOM);
      }
    });
  },
  methods: {
    ...mapActions(['getRoomInfo', 'getUserInfo', 'getTempUserInfo', 'getLastsignal', 'userLogin']),
    ...mapMutations([
      SET_TOKEN,
      mutationTypes.SET_ROOM_ID,
      mutationTypes.SET_FREE_TIME,
      mutationTypes.SET_ROOM_INFO,
      mutationTypes.SET_USER_SOURCE,
    ]),
    // 处理是否支持 webRTC
    async handleSupportWebRTC() {
      const supportResult = await TRTC.checkSystemRequirements();
      const {isWebRTCSupported, isH264DecodeSupported} = supportResult.detail;
      if (!isWebRTCSupported || !isH264DecodeSupported) {
        this.$store.commit(SET_IS_SUPPORT_WEBRTC, false);
        this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.CDN);
      }
    },
    signalHandle() {

      const {tourist, token} = localStorage.get('authData') || {};
      if (tourist) {
        this.$refs.modelRef.show(REGISTER);
        return
      } else {
        // this.headerClick(PICK_UP_SIGNAL);
      }

      // this.headerClick(PICK_UP_SIGNAL);
      getLastSignal({ roomId: this.currentRoomId })
          .then((res) => {
            if (res.data.canGetSignal) {
            this.headerClick(PICK_UP_SIGNAL);
            } else {
              layer.msg(this.$t('CantGetSignal tips'), {icon: 0,time:5000}, function(){
                // layer.msg('提示框关闭后的回调');
                // window.ECHAT.customMiniChat();
                window.open('https://t.me/YCHpromanager', '_blank');

              });

            }
          });

    },
    // 处理页面数据
    handlePlayerInfo() {
      this.getTempUserInfo();
    },
    // 退出直播间
    async handleExit() {
      // todo: 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // todo: 处理退出登录
    },
    headerClick(type) {
      this.$refs.modelRef.show(type);
    },
    openLicky(){
      this.$refs.modelRef.show(LUCKY_ACTIVITY);
    },
    handleFreeTimeEnd() {
      const index = layer.alert(this.$t('header.Free time end'),{
        icon:0,closeBtn: 0,btn: ['OK'],title:'Tips'
      },()=>{
        // window.ECHAT.customMiniChat();
        window.open('https://t.me/YCHpromanager', '_blank');

        layer.close(index)
      })
      // this.$refs.modelRef.show(VIP_REGISTER_TIP);
      // this[mutationTypes.SET_ROOM_INFO]({});
      this.$refs.videoPcRef.freeTimeEnd();
      socket.onClose();
    },
  },
  created() {
    // 判断是否支持 webrtc, 不支持 webrtc 的网页采用【线路三：标准直播】播放，且不可切换线路
    this.handleSupportWebRTC();

    // 处理监听事件
    this.$eventBus.$on('freeTimeEnd', this.handleFreeTimeEnd);
    this.$eventBus.$on(MESSAGETYPE.TRADE_SIGNAL_UPDATE, this.getLastsignal);
    // this.$eventBus.$on(MESSAGETYPE.PRIVATE_MESSAGE, (data) => {
    //   console.log(data);
    //   this.$refs.modelRef.show(PRIVATE_CHAT, data);
    // });
    this.$eventBus.$on(MESSAGETYPE.PRIVATE_MESSAGE, (data) => {
      // this[ADD_PRIVATE_CHAT_MESSAGE](data)
      this.$nextTick(() => {
        // this.getPrivateChatMsgList()
        this.$refs.modelRef.show(PRIVATE_CHAT, data);
      });
    });
    // this.$eventBus.$on('exit', this.handleExit);
    // this.$eventBus.$on('logout', this.handleLogout);
    this.$eventBus.$on('showLoginCard',()=>{
      this.$refs.modelRef.show(LOGIN);
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('freeTimeEnd', this.handleFreeTimeEnd);
    this.$eventBus.$off(MESSAGETYPE.TRADE_SIGNAL_UPDATE, this.getLastsignal);
    // this.$eventBus.$off('exit', this.handleExit);
    // this.$eventBus.$off('logout', this.handleLogout);
    // this.$eventBus.$off('showLoginCard', this.handleShowLoginCard);
  },
};
</script>

<style lang="less">


.layui-layer-btn .layui-layer-btn0 {
  border-color: #d51820;
  background-color: #d51820;
  color: #fff;
}

.playerContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: rgba(40, 40, 40, 1);
  opacity: 1;
}

.content {
  display: flex;
  flex: 1;
  margin-bottom: 12px;
  overflow: auto;

  .left {
    display: flex;
    flex: 2;
    flex-direction: column;

    .videoContaienr {
      flex: 1;
      height: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &_footerContainer {
      display: flex;
      height: 190px;
      justify-content: space-between;
      margin-top: 12px;
    }
  }


}

.WechatQrCode {
  display: flex;
  flex-direction: column;
  align-items: center;

  .qrCode {
    margin: 12px;
    width: 152px;
    height: 152px;
  }
}

.footer {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(33, 34, 34);
  color: #767676;

  a {
    color: #767676;
    text-decoration: #767676;
  }
}

body {
  background: rgb(40, 40, 40);
}


</style>
<style>
.echat-icon {
  bottom: 150px !important;
  right: 10px !important;
}
</style>
